// @mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  DialogActions,
  Button,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import { PropsDialog } from './types';

export default function BannerDialog({ open, close, bannerData, ...other }: PropsDialog) {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={close}
      {...other}
      PaperProps={{
        style: {
          // minHeight: '90%',
          maxHeight: '60%',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
        <Box sx={{ m: 0, backgroundColor: (theme) => theme.palette.primary.main }}>
          <Typography color="white" variant="h6">
            {bannerData?.title}
          </Typography>
        </Box>
        {bannerData?.closable && (
          <Iconify
            sx={{
              position: 'absolute',
              right: 18,
              top: 24,
              cursor: 'pointer',
              color: 'white',
            }}
            onClick={close}
            icon="ic:round-close"
          />
        )}
      </DialogTitle>

      <DialogContent>
        <Box sx={{ py: 3 }}>
          <Markdown
            sx={{
              p: 1,
              '& p, li, ol': {
                typography: 'body2',
              },
              '& ol': {
                p: 0,
                display: { md: 'flex' },
                listStyleType: 'none',
                '& li': {
                  '&:first-of-type': {
                    minWidth: 240,
                    mb: { xs: 0.5, md: 0 },
                  },
                },
              },
            }}
          >
            {bannerData?.message ?? ''}
          </Markdown>
        </Box>
      </DialogContent>
      {bannerData?.closable && (
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={close}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
