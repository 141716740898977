// react
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// auth
import GuestGuard from 'src/auth/guard/guest-guard';

// components
import { BannerRoot } from 'src/components/reldyn/dialog-modal';

// sections
import Login from 'src/sections/auth/Login';

// services
import { useAppDispatch } from 'src/hooks/hooks';
import { getFeatureFlagsList } from 'src/services/feature-flags/features';
import { useBannerQuery } from 'src/services/login';
import { setFeatures } from 'src/slices/featureSlices';
import { snackbarErrorResponse } from 'src/utils/functions';

// ----------------------------------------------------------------------
export default function LoginPage() {
  // banner message
  const { isLoading, isSuccess, data } = useBannerQuery(null);
  const [bannerObj, setBannerObj] = useState<BannerRoot>();
  const dispatch = useAppDispatch();

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (isSuccess && data) {
      setBannerObj(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------
  const getData = useCallback(async () => {
    try {
      const resp = await getFeatureFlagsList();

      const { data: featuresData, status } = resp;
      if (status === 200) {
        dispatch(setFeatures({ features: featuresData, disable: false }));
      }
    } catch (err) {
      dispatch(setFeatures({ features: [], disable: true }));
      snackbarErrorResponse(err, 'Something went wrong');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------
  return (
    <>
      <Helmet>
        <title> Login | Reldyn BaaS </title>
      </Helmet>
      {isLoading && (
        <Box
          sx={{
            marginRight: '20px',
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        >
          <CircularProgress size="1rem" />
        </Box>
      )}

      <GuestGuard>
        <Login bannerData={bannerObj!} />
      </GuestGuard>
    </>
  );
}
