// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  url: 'https://reldyn.co/',
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    changePassword: `${ROOTS.AUTH}/change-password`,
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    banking: `${ROOTS.DASHBOARD}/banking`,
    customers: `${ROOTS.DASHBOARD}/customers`,

    countries: {
      root: `${ROOTS.DASHBOARD}/master-data/countries`,
      new: `${ROOTS.DASHBOARD}/master-data/countries/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/countries/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/countries/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/countries/list`,
    },

    states: {
      root: `${ROOTS.DASHBOARD}/master-data/states`,
      new: `${ROOTS.DASHBOARD}/master-data/states/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/states/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/states/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/states/list`,
    },
    maritalStatuses: {
      root: `${ROOTS.DASHBOARD}/master-data/marital-statuses`,
      new: `${ROOTS.DASHBOARD}/master-data/marital-statuses/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/marital-statuses/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/marital-statuses/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/marital-statuses/list`,
    },
    branch: {
      root: `${ROOTS.DASHBOARD}/master-data/branch`,
      new: `${ROOTS.DASHBOARD}/master-data/branch/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/branch/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/branch/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/branch/list`,
    },
    idtype: {
      root: `${ROOTS.DASHBOARD}/master-data/id-type`,
      new: `${ROOTS.DASHBOARD}/master-data/id-type/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/id-type/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/id-type/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/id-type/list`,
    },
    accountPurpose: {
      root: `${ROOTS.DASHBOARD}/master-data/account-purpose`,
      new: `${ROOTS.DASHBOARD}/master-data/account-purpose/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/account-purpose/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/account-purpose/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/account-purpose/list`,
    },
    ticketStatus: {
      root: `${ROOTS.DASHBOARD}/master-data/ticket-status`,
      new: `${ROOTS.DASHBOARD}/master-data/ticket-status/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/ticket-status/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/ticket-status/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/ticket-status/list`,
    },
    ticketCategory: {
      root: `${ROOTS.DASHBOARD}/master-data/ticket-category`,
      new: `${ROOTS.DASHBOARD}/master-data/ticket-category/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/ticket-category/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/ticket-category/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/ticket-category/list`,
    },
    occupations: {
      root: `${ROOTS.DASHBOARD}/master-data/occupations`,
      new: `${ROOTS.DASHBOARD}/master-data/occupations/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/occupations/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/occupations/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/occupations/list`,
    },
    departments: {
      root: `${ROOTS.DASHBOARD}/master-data/departments`,
      new: `${ROOTS.DASHBOARD}/master-data/departments/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/departments/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/departments/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/departments/list`,
    },

    annualIncomes: {
      root: `${ROOTS.DASHBOARD}/master-data/annual-incomes`,
      new: `${ROOTS.DASHBOARD}/master-data/annual-incomes/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/annual-incomes/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/annual-incomes/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/annual-incomes/list`,
    },

    empTypes: {
      root: `${ROOTS.DASHBOARD}/master-data/emp-types`,
      new: `${ROOTS.DASHBOARD}/master-data/emp-types/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/emp-types/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/emp-types/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/emp-types/list`,
    },

    securityQuestions: {
      root: `${ROOTS.DASHBOARD}/master-data/security-questions`,
      new: `${ROOTS.DASHBOARD}/master-data/security-questions/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/security-questions/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/security-questions/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/security-questions/list`,
    },

    banks: {
      root: `${ROOTS.DASHBOARD}/master-data/banks`,
      new: `${ROOTS.DASHBOARD}/master-data/banks/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/banks/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/banks/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/banks/list`,
    },
    employmentSector: {
      root: `${ROOTS.DASHBOARD}/master-data/employment-sector`,
      new: `${ROOTS.DASHBOARD}/master-data/employment-sector/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/employment-sector/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/employment-sector/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/employment-sector/list`,
    },
    postalCode: {
      root: `${ROOTS.DASHBOARD}/master-data/postal-codes`,
      new: `${ROOTS.DASHBOARD}/master-data/postal-codes/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/postal-codes/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/postal-codes/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/postal-codes/list`,
    },
    transactionRequestType: {
      root: `${ROOTS.DASHBOARD}/master-data/transaction-request-type`,
      new: `${ROOTS.DASHBOARD}/master-data/transaction-request-type/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/transaction-request-type/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/transaction-request-type/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/transaction-request-type/list`,
    },
    transactionType: {
      root: `${ROOTS.DASHBOARD}/master-data/transaction-type`,
      new: `${ROOTS.DASHBOARD}/master-data/transaction-type/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/transaction-type/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/transaction-type/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/transaction-type/list`,
    },
    accountClosureReasons: {
      root: `${ROOTS.DASHBOARD}/master-data/account-closure-reasons`,
      new: `${ROOTS.DASHBOARD}/master-data/account-closure-reasons/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/account-closure-reasons/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/account-closure-reasons/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/account-closure-reasons/list`,
    },
    txnReqSubTypes: {
      root: `${ROOTS.DASHBOARD}/master-data/txn-req-sub-types`,
      new: `${ROOTS.DASHBOARD}/master-data/txn-req-sub-types/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/txn-req-sub-types/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/txn-req-sub-types/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/txn-req-sub-types/list`,
    },
    sysConfigs: {
      root: `${ROOTS.DASHBOARD}/master-data/system-config`,
      new: `${ROOTS.DASHBOARD}/master-data/system-config/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/system-config/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/system-config/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/system-config/list`,
    },

    bannerMessage: {
      root: `${ROOTS.DASHBOARD}/master-data/banner-message`,
      new: `${ROOTS.DASHBOARD}/master-data/banner-message/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/master-data/banner-message/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/master-data/banner-message/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/master-data/banner-message/list`,
    },

    // Consent management
    consentManagement: {
      root: `${ROOTS.DASHBOARD}/consent-management/cmd-documents`,
      new: `${ROOTS.DASHBOARD}/consent-management/cmd-documents/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/consent-management/cmd-documents/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/consent-management/cmd-documents/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/consent-management/cmd-documents/list`,
    },

    consents: {
      root: `${ROOTS.DASHBOARD}/consent-management/consents`,
      new: `${ROOTS.DASHBOARD}/consent-management/consents/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/consent-management/consents/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/consent-management/consents/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/consent-management/consents/list`,
    },
    // Application Consent management
    application: {
      root: `${ROOTS.DASHBOARD}/consent-management/application-contexts`,
      new: `${ROOTS.DASHBOARD}/consent-management/application-contexts/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/consent-management/application-contexts/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/consent-management/application-contexts/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/consent-management/application-contexts/list`,
    },

    // Ticket management
    ticketManagement: {
      root: `${ROOTS.DASHBOARD}/ticket-management/tickets`,
      list: `${ROOTS.DASHBOARD}/ticket-management/tickets/list`,
      new: `${ROOTS.DASHBOARD}/ticket-management/tickets/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/ticket-management/tickets/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/ticket-management/tickets/${id}/edit`,
    },

    // Onboarding management
    onboardingManagement: {
      root: `${ROOTS.DASHBOARD}/onboarding-management/onboarding`,
      list: `${ROOTS.DASHBOARD}/onboarding-management/onboarding/list`,
      new: `${ROOTS.DASHBOARD}/onboarding-management/onboarding/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/onboarding-management/onboarding/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/onboarding-management/onboarding/${id}/edit`,
    },

    // Current user
    currentUser: {
      root: `${ROOTS.DASHBOARD}/current-user`,
      user: `${ROOTS.DASHBOARD}/current-user`,
      edit: `${ROOTS.DASHBOARD}/current-user-edit`,
    },

    // User management
    userManagement: {
      root: `${ROOTS.DASHBOARD}/user-management/users`,
      new: `${ROOTS.DASHBOARD}/user-management/users/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user-management/users/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user-management/users/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/user-management/users/list`,
    },

    permissions: {
      root: `${ROOTS.DASHBOARD}/user-management/permissions`,
      new: `${ROOTS.DASHBOARD}/user-management/permissions/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user-management/permissions/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user-management/permissions/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/user-management/permissions/list`,
    },

    roles: {
      root: `${ROOTS.DASHBOARD}/user-management/roles`,
      new: `${ROOTS.DASHBOARD}/user-management/roles/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/user-management/roles/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user-management/roles/${id}/edit`,
      list: `${ROOTS.DASHBOARD}/user-management/roles/list`,
    },

    passwordPolicy: {
      root: `${ROOTS.DASHBOARD}/user-management/password-policy`,
    },
    securityPolicy: {
      root: `${ROOTS.DASHBOARD}/user-management/security-policy`,
    },

    usernamePolicy: {
      root: `${ROOTS.DASHBOARD}/user-management/username-policy`,
    },

    // feature data
    services: {
      root: `${ROOTS.DASHBOARD}/feature-data/services`,
      list: `${ROOTS.DASHBOARD}/feature-data/services/list`,
    },

    features: {
      root: `${ROOTS.DASHBOARD}/feature-data/features`,
      list: `${ROOTS.DASHBOARD}/feature-data/features/list`,
    },
  },
};
