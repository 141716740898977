import { SnackbarProperties } from 'src/types/global';

export const API_ROOT = '';

// logo
export const RD_BANK_LOGO_LARGE_SIZE = '/logo/logo_full.svg';
export const RD_BANK_LOGO_SMALL_SIZE = '/logo/logo_single.svg';

// feature flag
export const MAX_RECONNECT_ATTEMPTS = 3;
export const RECONNECT_DELAY_MS = 500;

// global
export const RD_BUTTON_SIZE = 'medium';
export const RD_SEARCH_BUTTON_SIZE = 'large';
export const ACTIVITY_LOG_HEADER = 'Activity Logs';
export const METADATA_HEADER = 'Metadata';
export const REFRESH_BUTTON = 'ic:outline-sync';

// create new button spec
export const RD_CN_BUTTON_ICON = 'eva:plus-fill';
export const RD_CN_BUTTON_STYLE = { fontWeight: 500 };
export const RD_CN_BUTTON_VARIANT = 'contained';

// edit button spec
export const RD_EDIT_BUTTON_ICON = 'material-symbols:edit-outline-sharp';
export const RD_EDIT_BUTTON_STYLE = {};
export const RD_EDIT_BUTTON_VARIANT = 'contained';

// create and update button spec
export const RD_UPDATE_BUTTON_ICON = 'material-symbols:edit-outline-sharp';
export const RD_UPDATE_BUTTON_STYLE = {};
export const RD_CU_BUTTON_VARIANT = 'contained';

// cancel button spec
export const RD_CANCEL_BUTTON_ICON = 'iconoir:cancel';
export const RD_CANCEL_BUTTON_STYLE = {};
export const RD_CANCEL_BUTTON_VARIANT = 'outlined';
export const RD_CANCEL_BUTTON_COLOR = 'secondary';

// delete button spec
export const RD_DELETE_BUTTON_ICON = 'eva:trash-2-outline';
export const RD_DELETE_BUTTON_STYLE = { ml: 2 };
export const RD_DELETE_BUTTON_VARIANT = 'outlined';
export const RD_DELETE_BUTTON_COLOR = 'error';

// dialog box spec
export const RD_DB_DELETE_BUTTON_ICON = 'eva:trash-2-outline';
export const RD_DB_DELETE_BUTTON_STYLE = {};
export const RD_DB_DELETE_BUTTON_VARIANT = 'contained';
export const RD_DB_DELETE_BUTTON_COLOR = 'secondary';

export const RD_DB_CLOSE_BUTTON_ICON = 'iconoir:cancel';
export const RD_DB_CLOSE_BUTTON_STYLE = {};
export const RD_DB_CLOSE_BUTTON_VARIANT = 'contained';
export const RD_DB_CLOSE_BUTTON_COLOR = 'secondary';

// search toolbar
export const RD_ST_RESET_BUTTON_ICON = 'mdi:restart';
export const RD_ST_RESET_BUTTON_STYLE = { flexShrink: 0, fontWeight: 500 };
export const RD_ST_RESET_BUTTON_VARIANT = 'outlined';
export const RD_ST_RESET_BUTTON_COLOR = 'secondary';
export const RD_ST_RESET_BUTTON_SIZE = 'large';

export const RD_ST_SEARCH_BUTTON_ICON = 'eva:search-fill';
export const RD_ST_SEARCH_BUTTON_STYLE = { flexShrink: 0, fontWeight: 500 };
export const RD_ST_SEARCH_BUTTON_VARIANT = 'soft';
export const RD_ST_SEARCH_BUTTON_COLOR = 'secondary';
export const RD_ST_SEARCH_BUTTON_SIZE = 'large';

// masterdata , ticket service, feature flags
export const BUTTON_VARIANT = 'contained';
export const CANCEL_BUTTON_VARIANT = 'outlined';
export const DETAILS_DELETE_BUTTON_ICON = 'eva:trash-2-outline';
export const EDIT_BUTTON_ICON = 'material-symbols:edit-outline-sharp';
export const DELETE_BUTTON_COLOR = 'error';

export const CANCEL_BUTTON_COLOR = 'secondary';
export const SEARCH_BUTTON_COLOR = 'secondary';
export const RESET_BUTTON = 'mdi:restart';

export const DATE_TIME_FMT_01 = 'dd MMM yyyy hh:mm aa';

// Snackbar constants
export const SNACKBAR_OPTIONS_FOR_ERROR: SnackbarProperties = {
  variant: 'error',
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
};

export const TAB_STYLE = {
  width: 1,
  bottom: 0,
  zIndex: 9,
  position: 'absolute',
  bgcolor: 'background.paper',
  '& .MuiTabs-flexContainer': {
    pr: { md: 3 },
    justifyContent: {
      sm: 'center',
      md: 'flex-end',
    },
  },
};

// IMAGE URLS
export const DUMMY_IMAGE = '/assets/dummy-user.jpeg';

// Ticket closed status
export const TICKET_CLOSED = 'Closed';
