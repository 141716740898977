import axios, { AxiosInstance } from 'axios';

import { logout, setToken } from 'src/slices/authSlice';
import { setPopUp } from 'src/slices/flagsSlice';
import { store } from 'src/store/store';
import { LoginResponse } from 'src/types/auth';

export const ApiClient: AxiosInstance = axios.create({});

//------------------------------------------------------------------------------------------------

type AccessTokenSubscriber = (accessToken: string) => void;
type AccessTokenRefresh = (onAccessTokenRefreshed: string) => void;
type RefreshSubscriber = (refreshSubscribers: string) => void;

// ------------------------------------------------------------------------------------------------

let isRefreshing = false;
let refreshSubscribers: RefreshSubscriber[] = [];

const subscribeTokenRefresh = (onAccessTokenRefreshed: AccessTokenRefresh) => {
  refreshSubscribers.push(onAccessTokenRefreshed);
};

const onAccessTokenRefreshed = (accessToken: string) => {
  refreshSubscribers.forEach((subscriber: AccessTokenSubscriber) => subscriber(accessToken));
  refreshSubscribers = [];
};

ApiClient.interceptors.request.use(
  async (config) => {
    const { expires_at, refresh_expires_at, refresh_token, token } = store.getState().reducer.login;

    if (token && !isTokenExpired(expires_at)) {
      // Add the access token to the request headers
      config.headers.Authorization = `Bearer ${token}`;
    } else if (refresh_token && !isRefreshTokenExpired(refresh_expires_at)) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          console.log('Refresh api started... ');

          const response = await refreshAccessToken(refresh_token);
          console.log('Refresh api success ');

          isRefreshing = false;
          const { data, status } = response;
          if (status === 200) {
            onAccessTokenRefreshed(data?.token);
            store.dispatch(setToken(data as LoginResponse));
            // Update the original request with the new access token
            config.headers.Authorization = `Bearer ${data?.token}`;
            // Retry the original request
            return config;
          }
        } catch (error) {
          console.log(error, 'Refresh api failed ');

          isRefreshing = false;
          // Handle error refreshing token (e.g., redirect to login)
          store.dispatch(logout());
          store.dispatch(setPopUp(false));
        }
      } else {
        // Wait for token refresh and retry the original request
        return new Promise((resolve) => {
          subscribeTokenRefresh((accessToken: string) => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            resolve(config);
          });
        });
      }
    } else {
      store.dispatch(logout());
      store.dispatch(setPopUp(false));
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const isTokenExpired = (tokenExpiry: number) => {
  const currentDateAndTime = Date.now();
  // Return true if the token has expired, false otherwise
  if (tokenExpiry < currentDateAndTime) {
    return true;
  }
  return false;
};

const isRefreshTokenExpired = (refreshTokenExpiry: number) => {
  const currentDateAndTime = Date.now();
  // Return true if the refresh token has expired, false otherwise
  if (refreshTokenExpiry < currentDateAndTime) {
    return true;
  }
  return false;
};

const refreshAccessToken = (refreshToken: string) => {
  const response = axios.post(
    '/security/v1/auth/refresh-token',
    {},
    { headers: { Authorization: `Bearer ${refreshToken}` } }
  );
  return response;
};
