import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  changeProfile: boolean;
};

const initialState: InitialState = {
  changeProfile: false,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.changeProfile = action.payload;
    },
  },
});

export const { setCurrentUser } = currentUserSlice.actions;

export default currentUserSlice.reducer;
