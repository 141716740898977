// form
import { Controller, useFormContext } from 'react-hook-form';

// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------------------------------------------------------------
type Props = TextFieldProps & {
  name: string;
  multiline?: boolean;
  rows?: number;
  onStateChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

// ----------------------------------------------------------------------
export default function RHFTextPolicy({
  name,
  multiline,
  rows,
  helperText,
  type,
  onStateChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  // ----------------------------------------------------------------------
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          multiline={multiline}
          rows={rows}
          onChange={(event) => {
            let { value } = event.target;
            value = value.replace(/[^0-9]/g, '');
            if (type === 'number') {
              field.onChange(Number(value));
            } else {
              field.onChange(value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}

// ----------------------------------------------------------------------
export function RHFTextSwitchPolicy({
  name,
  multiline,
  rows,
  helperText,
  type,
  onStateChange,
  ...other
}: Props) {
  const { control } = useFormContext();

  // ----------------------------------------------------------------------
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          multiline={multiline}
          rows={rows}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
