import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { RD_BANK_LOGO_LARGE_SIZE, RD_BANK_LOGO_SMALL_SIZE } from 'src/utils/constants';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isLarge?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, isLarge, ...other }, ref) => {
    const bankLogo = (
      <Box
        component="img"
        src={isLarge ? RD_BANK_LOGO_LARGE_SIZE : RD_BANK_LOGO_SMALL_SIZE}
        sx={{ width: 140, height: 40, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return bankLogo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {bankLogo}
      </Link>
    );
  }
);

export default Logo;
