import { useCallback, useEffect, useState } from 'react';
import { Traits } from 'src/utils/feature-flag-utils';
import { useAppSelector } from './hooks';

type ReturnType = {
  isEnabled?: boolean;
  traits: Traits | null;
  description: string;
};

type FeatureType = {
  description: string;
  enabled?: boolean;
  key: string;
  name: string;
  traits: Traits | null;
};

export default function useIsFeatureOn(featureKey: string, defaultEnabled?: boolean): ReturnType {
  const { features, disable } = useAppSelector((state) => state.reducer.feature);

  const [feature, setFeature] = useState<FeatureType>({
    description: '',
    enabled: false,
    key: '',
    name: '',
    traits: {},
  });

  const checkFeature = useCallback(() => {
    if (features.length !== 0) {
      const featureItem = features.find((item) => item.key === featureKey);
      if (featureItem !== undefined) {
        setFeature(featureItem);
      } else {
        setFeature({
          description: '',
          enabled: defaultEnabled ?? false,
          key: '',
          name: '',
          traits: {},
        });
      }
    } else if (disable) {
      setFeature({
        description: '',
        enabled: true,
        key: '',
        name: '',
        traits: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureKey, features, defaultEnabled]);

  useEffect(() => {
    checkFeature();
  }, [checkFeature]);

  return {
    isEnabled: feature?.enabled,
    traits: feature?.traits,
    description: feature?.description,
  };
}
