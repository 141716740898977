import qs from 'qs';
import { API_ROOT } from 'src/utils/constants';
import { FeatureOnSubmit } from 'src/types/featuredata/feature';
import { ApiClient } from '../config';

const FEATURES_URL = `${API_ROOT}/feature-flags/v1/feature-flags`;

type FeatureFlagListPayload = {
  serviceId: string;
  size: number;
  page: number;
  sort: string;
  q: string;
};

export const getFeatureFlagsList = () => {
  const resp = ApiClient.get(`${FEATURES_URL}/list`);
  return resp;
};

export const getFeatureFlagsByServiceId = ({
  serviceId,
  size,
  page,
  sort,
  q,
}: FeatureFlagListPayload) => {
  const resp = ApiClient.get(FEATURES_URL, {
    params: { serviceId, size, page, sort, q },
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
  return resp;
};

export const updateFeatureFlag = (id: string, data: FeatureOnSubmit) => {
  const resp = ApiClient.put(`${FEATURES_URL}/${id}`, data);
  return resp;
};

export const createFeatureFlag = (data: FeatureOnSubmit) => {
  const resp = ApiClient.post(`${FEATURES_URL}`, data);
  return resp;
};

export const getFeatureDetails = (id: string) => {
  const resp = ApiClient.get(`${FEATURES_URL}/${id}`);

  return resp;
};

export const deleteFeatureFlag = (id: string) => {
  const resp = ApiClient.delete(`${FEATURES_URL}/${id}`);
  return resp;
};
