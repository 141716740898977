import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storage from 'redux-persist/lib/storage/session';

import authReducer from '../slices/authSlice';
import currentUserReducer from '../slices/currentUserSlice';
import featureReducer from '../slices/featureSlices';
import flagsReducer from '../slices/flagsSlice';
import pageReducer from '../slices/pageSlices';
import userReducer from '../slices/userSlice';

// eslint-disable-next-line import/no-cycle
import { baseQuery } from './basequery/basequery';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login', 'page'],
};

const rootReducer = combineReducers({
  page: pageReducer,
  login: authReducer,
  user: userReducer,
  feature: featureReducer,
  flags: flagsReducer,
  currentUser: currentUserReducer,
});

const reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: { reducer, [baseQuery.reducerPath]: baseQuery.reducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([baseQuery.middleware]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
