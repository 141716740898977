// react
import { useCallback, useEffect, useState } from 'react';

// @mui
import { Box, Link, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// components
import { CustomAvatar } from 'src/components/custom-avatar';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

// services
import { getAvatar } from 'src/services/auth/auth';

// slices
import { setCurrentUser } from 'src/slices/currentUserSlice';

// ----------------------------------------------------------------------
const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------
export default function NavAccount() {
  const { full_name, email } = useAppSelector((state) => state.reducer.user);
  const [imageData, setImageData] = useState('');
  const { changeProfile } = useAppSelector((state) => state.reducer.currentUser);
  const dispatch = useAppDispatch();

  // ----------------------------------------------------------------------
  const getCurrentAvatar = useCallback(async () => {
    try {
      const res = await getAvatar();
      if (res.status === 200) {
        setImageData(res.data);
        dispatch(setCurrentUser(false));
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCurrentAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (changeProfile) getCurrentAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeProfile]);

  return (
    <Link underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar
          src={`data:image/png;base64, ${imageData}`}
          alt={`${full_name} avatar`}
          name={`${full_name} avatar`}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {full_name ?? '--'}
          </Typography>

          <Typography variant="caption" noWrap sx={{ color: 'text.secondary' }}>
            {email ?? '--'}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
