import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Traits } from 'src/utils/feature-flag-utils';

export interface FeatureState {
  features: Array<{
    description: string;
    enabled: boolean;
    key: string;
    name: string;
    traits: Traits | null;
  }>;
  disable: boolean;
}

const initialState: FeatureState = {
  features: [],
  disable: false,
};

export const featureSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<FeatureState>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setFeatures } = featureSlice.actions;

export default featureSlice.reducer;
