// react
import { useCallback, useEffect } from 'react';

// routes
import { useRouter } from 'src/routes/hook';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

// paths
import { paths } from 'src/routes/paths';
import { setAuthenticated } from 'src/slices/authSlice';

// ----------------------------------------------------------------------
type GuestGuardProps = {
  children: React.ReactNode;
  closeConnection?: () => void;
};

// ----------------------------------------------------------------------
export default function GuestGuard({
  closeConnection,
  children,
}: Readonly<GuestGuardProps>) {
  const router = useRouter();
  const { isAuthenticated, change_password_at_next_login, password_expiry_after_frequency_days } =
    useAppSelector((state) => state.reducer.login);
  const dispatch = useAppDispatch();

  // ----------------------------------------------------------------------
  const check = useCallback(() => {
    if (isAuthenticated && change_password_at_next_login === false) {
      router.replace(paths.dashboard.root);
    }
    if (change_password_at_next_login) {
      dispatch(setAuthenticated(false));
      router.push(paths.auth.changePassword);
    }
    if (password_expiry_after_frequency_days) {
      dispatch(setAuthenticated(false));
      router.push(paths.auth.changePassword);
    }
    if (closeConnection && isAuthenticated) {
      closeConnection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, router]);

  // ----------------------------------------------------------------------
  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
