// @mui
import { Stack, Typography } from '@mui/material';

// utils
import { RD_BANK_LOGO_LARGE_SIZE } from 'src/utils/constants';
import { GetTrait } from 'src/utils/feature-flag-utils';

// hooks
import useIsFeatureOn from 'src/hooks/useIsFeatureOn';

// components
import Image from '../../components/image';

import { StyledContent, StyledRoot, StyledSection, StyledSectionBg } from './styles';

// ----------------------------------------------------------------------------------------
type Props = {
  children: React.ReactNode;
};

// ----------------------------------------------------------------------------------------
export default function LoginLayout({ children }: Props) {
  // logo feature flag
  const { isEnabled: isLogoEnabled, traits: logoTraits } = useIsFeatureOn('LOGIN_PAGE_LOGO', true);

  // copy right feature flag
  const copyRightEnabled = useIsFeatureOn('LOGIN_PAGE_COPYRIGHT_TEXT', true).isEnabled;

  // hero section enabled
  const { isEnabled: isHeroEnabled, traits: heroTraits } = useIsFeatureOn(
    'LOGIN_PAGE_HERO_SECTION',
    true
  );

  // ----------------------------------------------------------------------------------------
  return (
    <StyledRoot>
      <StyledSection>
        <StyledSectionBg />
        {isLogoEnabled ? (
          <img
            alt="img"
            src={GetTrait(logoTraits, 'logo', RD_BANK_LOGO_LARGE_SIZE)}
            style={{ width: 240, height: 80, margin: 35 }}
          />
        ) : (
          <img
            alt="img"
            src={RD_BANK_LOGO_LARGE_SIZE}
            style={{ width: 240, height: 80, margin: 35 }}
          />
        )}

        <Typography variant="h3" sx={{ mb: 2, maxWidth: 480, textAlign: 'center' }}>
          {heroTraits?.title ?? 'Hi, Welcome back'}
        </Typography>

        {isHeroEnabled ? (
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src={GetTrait(heroTraits, 'hero', '/assets/illustrations/illustration_dashboard.png')}
            sx={{ maxWidth: 720 }}
          />
        ) : (
          <Image
            disabledEffect
            visibleByDefault
            alt="auth"
            src="/assets/illustrations/illustration_dashboard.png"
            sx={{ maxWidth: 720 }}
          />
        )}

        <footer style={{ color: 'gray', position: 'fixed', bottom: 20 }}>
          {copyRightEnabled && (
            <Typography variant="body2">
              Copyright RELDYN, All right reserved. Version 0.1.2a - build: 9dkzujslsi3kd
            </Typography>
          )}
        </footer>
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
