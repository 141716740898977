// react
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

// yup
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';

// react google recaptcha
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// components
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';

// services
import { useLoginMutation } from 'src/services/login';

// types
import { FormValuesProps, LoginFailure, LoginRequest } from 'src/types/auth';

// hooks
import { useAppDispatch } from 'src/hooks/hooks';

// slices
import { setToken } from 'src/slices/authSlice';

// schemas
import { LoginSchema } from 'src/schemas/auth';

// ----------------------------------------------------------------------
export default function AuthLoginForm() {
  // login api
  const [loginMethod, { isLoading, isSuccess, isError, error , data }] = useLoginMutation();
  const loginError = error  as LoginFailure

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [myToken, setMyToken] = useState('');

  const SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

  // ----------------------------------------------------------------------
  const defaultValues = {
    username: '',
    password: '',
  };

  // ----------------------------------------------------------------------
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  // ----------------------------------------------------------------------
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  // ----------------------------------------------------------------------
  const onSubmit = async (formData: LoginRequest) => {
    const loginData = {
      password: formData.password,
      username: formData.username,
      captcha_token: myToken,
    };
    loginMethod(loginData);
  };

  // ----------------------------------------------------------------------
  const verifyRecaptchaCallback = useCallback((token: string) => {
    setMyToken(token);
  }, []);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (isSuccess) {
      dispatch(setToken(data!));
    } else if (isError) {
      reset();

      setError('afterSubmit', {
        ...error as LoginFailure,
        message:
          'status' in error! &&
          (error.status === 401 || error?.status === 403 || error?.status === 400)
            ? loginError?.data?.message ?? 'Invalid Credentials'
            : 'Something went wrong',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, isError, isSuccess, error, reset, setError, dispatch, navigate]);

  // ----------------------------------------------------------------------
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={SITE_KEY!}
      container={{
        // optional to render inside custom element
        // element: '[required_id_or_htmlelement]',
        parameters: {
          badge: 'bottomright', // '[inline|bottomright|bottomleft]', // optional, default undefined
          // theme: 'dark', // optional, default undefined
        },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

          <RHFTextField name="username" label="Username" autoFocus />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack alignItems="flex-end" sx={{ my: 2 }}>
          <Link variant="body2" color="primary.main" underline="none" href="/auth/forgot-password">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          color="inherit"
          // size={RD_BUTTON_SIZE}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitSuccessful || isSubmitting}
          endIcon={<Iconify icon="eva:log-in-fill" />}
          sx={{
            // bgcolor: 'text.primary',
            bgcolor: 'primary.main',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            '&:hover': {
              bgcolor: 'primary.dark',
              // bgcolor: 'text.primary',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          }}
        >
          Login
        </LoadingButton>
      </FormProvider>
      <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
    </GoogleReCaptchaProvider>
  );
}
