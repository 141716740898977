// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// react
import { Client } from '@stomp/stompjs';
import { useEffect, useState } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import SockJS from 'sockjs-client';
import { WebSocket } from 'ws';

// routes
import Router from 'src/routes/sections';

// theme
import ThemeProvider from 'src/theme';

// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

// redux
import { persistor } from 'src/store/store';

// components
import MotionLazy from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar';
import NetworkStatusIndicator from './components/network-indicator';

// css
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useAppDispatch, useAppSelector } from './hooks/hooks';
import { setFeatures } from './slices/featureSlices';
import { featureItem } from './types/featuredata/feature';

Object.assign(global, { WebSocket });

const WEB_SOCKET_URL = 'https://dp01.reldyn.dev/ff-ws';

// ----------------------------------------------------------------------

export default function App() {
  console.log('Loading...');

  useScrollToTop();

  const { features } = useAppSelector((state) => state.reducer.feature);
  const dispatch = useAppDispatch();

  // create and update
  const [currentFeature, setCurrentFeature] = useState<featureItem>({
    name: '',
    key: '',
    description: '',
    enabled: true,
    traits: {},
  });

  // delete the feature
  const [deleteFeature, setDeleteFeature] = useState<featureItem>({
    name: '',
    key: '',
    description: '',
    enabled: false,
    traits: {},
  });

  // update and create and delete the feature flag
  const featuresArr = [...features];
  const featureIndex = features?.findIndex((item: featureItem) => item.key === currentFeature.key);

  // ----------------------------------------------------------------------
  const deleteTheFeature = () => {
    const featuresArray = featuresArr.filter((item) => item.key !== deleteFeature.key);
    dispatch(setFeatures({ features: featuresArray, disable: false }));
  };

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (currentFeature && featureIndex !== -1) {
      featuresArr[featureIndex] = currentFeature;
      dispatch(setFeatures({ features: featuresArr, disable: false }));
    }
    if (currentFeature && featureIndex === -1) {
      featuresArr.push(currentFeature);
      dispatch(setFeatures({ features: featuresArr, disable: false }));
    }
    if (deleteFeature) {
      deleteTheFeature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeature, featureIndex, deleteFeature]);

  useEffect(() => {
    setupEventSourcePublic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupEventSourcePublic = () => {
    const onConnected = () => {
      console.log('Connected!!');
      client.subscribe('/feature-flags/updates', (msg) => {

        const feature = JSON.parse(msg.body);

        if (feature?.command === 'DELETE') {
          setDeleteFeature(feature?.response);
        } else {
          setCurrentFeature(feature?.response);
        }
      });
    };

    const onDisconnected = () => {
      console.log('Disconnected!!');
    };

    const client = new Client({
      brokerURL: WEB_SOCKET_URL,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: onConnected,
      onDisconnect: onDisconnected,
      webSocketFactory: () => new SockJS(WEB_SOCKET_URL),
    });

    client.activate();
  };


  return (
    <>
      <NetworkStatusIndicator />

      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'bold', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: true,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </SnackbarProvider>
      </PersistGate>
    </>
  );
}
