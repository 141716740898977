export interface Traits {
  [key: string]: string | undefined;
}

export function GetTrait(
  currentTraits: Traits | null | undefined,
  key: string,
  defaultValue: string
) {
  if (currentTraits === null) return defaultValue;
  if (currentTraits === undefined) return defaultValue;
  if (currentTraits[key] === undefined) return defaultValue;
  return currentTraits[key];
}
