import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface PageState {
  page: number;
  rowsPerPage: number;
  dense?: boolean;
}

const initialState: PageState = {
  page: 0,
  rowsPerPage: 10,
  dense: true,
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setOffset: (state, action: PayloadAction<PageState>) => {
      state.page = action.payload.page;
      state.rowsPerPage = action.payload.rowsPerPage;
      state.dense = action.payload.dense;
    },
  },
});

export const { setOffset } = pageSlice.actions;

export default pageSlice.reducer;
