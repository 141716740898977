// react
import { forwardRef } from 'react';

// @iconify
import { Icon } from '@iconify/react';

// @mui
import Box, { BoxProps } from '@mui/material/Box';

// components
import { IconifyProps } from 'src/components/iconify/types';

// ----------------------------------------------------------------------
interface Props extends BoxProps {
  icon: IconifyProps;
}

// ----------------------------------------------------------------------
const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...other}
  />
));

export default Iconify;
