import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  username: Yup.string().required('username is required'),
  password: Yup.string().required('Password is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  current_password: Yup.string().required('Old Password is required'),
  new_password: Yup.string()
    .required('New Password is required')
    .min(6, 'Password must be at least 6 characters')
    .test(
      'no-match',
      'New password must be different than old password',
      (value, { parent }) => value !== parent.oldPassword
    ),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('new_password')], 'Passwords must match to new password'),
});

export const VerifyCodeSchema = Yup.object().shape({
  current_password: Yup.string().required('Old password is required'),
  password: Yup.string().required('New password is required'),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Password must match'),
});

export const ChangePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required('Old Password is required'),
  new_password: Yup.string()
    .required('New Password is required')
    .test(
      'no-match',
      'New password must be different than old password',
      (value, { parent }) => value !== parent.oldPassword
    ),
  confirm_password: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('new_password')], 'Passwords must match to new password'),
});

export const ForgetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});
