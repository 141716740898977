import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import LoadingScreen from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// Banking
const BankingPage = lazy(() => import('src/pages/dashboard/banking'));

// current user
const CurrentUser = lazy(() => import('src/pages/dashboard/current-user/user-profile'));
const EditCurrentUser = lazy(() => import('src/pages/dashboard/current-user/edit-user'));

// Country
const CountryDetailsPage = lazy(() => import('src/pages/dashboard/masterdata/countries/details'));
const CountryListPage = lazy(() => import('src/pages/dashboard/masterdata/countries/list'));
const CountryCreatePage = lazy(() => import('src/pages/dashboard/masterdata/countries/create'));
const CountryEditPage = lazy(() => import('src/pages/dashboard/masterdata/countries/edit'));

// State

const StateDetailsPage = lazy(() => import('src/pages/dashboard/masterdata/states/details'));
const StateListPage = lazy(() => import('src/pages/dashboard/masterdata/states/list'));
const StateCreatePage = lazy(() => import('src/pages/dashboard/masterdata/states/create'));
const StateEditPage = lazy(() => import('src/pages/dashboard/masterdata/states/edit'));

// occupations
const OccupationDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/occupations/details')
);
const OccupationListPage = lazy(() => import('src/pages/dashboard/masterdata/occupations/list'));
const OccupationCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/occupations/create')
);
const OccupationEditPage = lazy(() => import('src/pages/dashboard/masterdata/occupations/edit'));

// departments
const DepartmentDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/departments/details')
);
const DepartmentListPage = lazy(() => import('src/pages/dashboard/masterdata/departments/list'));
const DepartmentCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/departments/create')
);
const DepartmentEditPage = lazy(() => import('src/pages/dashboard/masterdata/departments/edit'));

// Annual Income
const AnnualIncomeDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/annual-income/details')
);

const AnnualIncomeListPage = lazy(
  () => import('src/pages/dashboard/masterdata/annual-income/list')
);
const AnnualIncomeCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/annual-income/create')
);
const AnnualIncomeEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/annual-income/edit')
);

// Emp Types
const EmpTypeDetailsPage = lazy(() => import('src/pages/dashboard/masterdata/emp-types/details'));
const EmpTypeListPage = lazy(() => import('src/pages/dashboard/masterdata/emp-types/list'));
const EmpTypeCreatePage = lazy(() => import('src/pages/dashboard/masterdata/emp-types/create'));
const EmpTypeEditPage = lazy(() => import('src/pages/dashboard/masterdata/emp-types/edit'));

// Security Question
const SecurityQuestionDetailsPage = lazy(
  () => import('../../pages/dashboard/masterdata/security-questions/details')
);
const SecurityQuestionListPage = lazy(
  () => import('src/pages/dashboard/masterdata/security-questions/list')
);
const SecurityQuestionCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/security-questions/create')
);
const SecurityQuestionEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/security-questions/edit')
);

// banks

const BankDetailsPage = lazy(() => import('src/pages/dashboard/masterdata/banks/details'));
const BankListPage = lazy(() => import('src/pages/dashboard/masterdata/banks/list'));
const BankCreatePage = lazy(() => import('src/pages/dashboard/masterdata/banks/create'));
const BankEditPage = lazy(() => import('src/pages/dashboard/masterdata/banks/edit'));

// Employment Sector
const EmploymentSectorDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/employment-sector/details')
);
const EmploymentSectorListPage = lazy(
  () => import('src/pages/dashboard/masterdata/employment-sector/list')
);
const EmploymentSectorCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/employment-sector/create')
);
const EmploymentSectorEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/employment-sector/edit')
);

// Postal Code
const PostalCodeDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/postal-codes/details')
);
const PostalCodeListPage = lazy(() => import('src/pages/dashboard/masterdata/postal-codes/list'));
const PostalCodeCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/postal-codes/create')
);
const PostalCodeEditPage = lazy(() => import('src/pages/dashboard/masterdata/postal-codes/edit'));

// TransactionRequestType
const TransactionRequestTypeDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-request-type/details')
);
const TransactionRequestTypeListPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-request-type/list')
);
const TransactionRequestTypeCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-request-type/create')
);
const TransactionRequestTypeEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-request-type/edit')
);

// TransactionType
const TransactionTypeDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-type/details')
);
const TransactionTypeListPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-type/list')
);
const TransactionTypeCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-type/create')
);
const TransactionTypeEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/transaction-type/edit')
);

// AccClosureReasons
const AccClosureReasonsDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/account-closure-reasons/details')
);
const AccClosureReasonsListPage = lazy(
  () => import('src/pages/dashboard/masterdata/account-closure-reasons/list')
);
const AccClosureReasonsCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/account-closure-reasons/create')
);
const AccClosureReasonsEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/account-closure-reasons/edit')
);

// TransactionRequestSubType
const TransactionRequestSubTypeDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/txn-req-sub-types/details')
);
const TransactionRequestSubTypeListPage = lazy(
  () => import('src/pages/dashboard/masterdata/txn-req-sub-types/list')
);
const TransactionRequestSubTypeCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/txn-req-sub-types/create')
);
const TransactionRequestSubTypeEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/txn-req-sub-types/edit')
);

// SystemConfig
const SystemConfigDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/system-config/details')
);
const SystemConfigListPage = lazy(
  () => import('src/pages/dashboard/masterdata/system-config/list')
);
const SystemConfigCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/system-config/create')
);
const SystemConfigEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/system-config/edit')
);
// ----------------------------------------------------------------------

// Marital Statuses
const MaritalStatusesDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/marital-statuses/details')
);
const MaritalStatusesListPage = lazy(
  () => import('src/pages/dashboard/masterdata/marital-statuses/list')
);
const MaritalStatusesCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/marital-statuses/create')
);
const MaritalStatusesEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/marital-statuses/edit')
);
// ----------------------------------------------------------------------

// Branch
const BranchDetailsPage = lazy(() => import('src/pages/dashboard/masterdata/branch/details'));
const BranchListPage = lazy(() => import('src/pages/dashboard/masterdata/branch/list'));
const BranchCreatePage = lazy(() => import('src/pages/dashboard/masterdata/branch/create'));
const BranchEditPage = lazy(() => import('src/pages/dashboard/masterdata/branch/edit'));
// ----------------------------------------------------------------------

// IDType
const IdTypeDetails = lazy(() => import('src/pages/dashboard/masterdata/id-type/details'));
const IdTypeListPage = lazy(() => import('src/pages/dashboard/masterdata/id-type/list'));
const IdTypeCreatePage = lazy(() => import('src/pages/dashboard/masterdata/id-type/create'));
const IdTypeEditPage = lazy(() => import('src/pages/dashboard/masterdata/id-type/edit'));
// ----------------------------------------------------------------------

// Account Purposes
const AccountPurposesDetails = lazy(
  () => import('src/pages/dashboard/masterdata/account-purpose/details')
);
const AccountPurposesListPage = lazy(
  () => import('src/pages/dashboard/masterdata/account-purpose/list')
);
const AccountPurposesCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/account-purpose/create')
);
const AccountPurposesEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/account-purpose/edit')
);

// -----------------------------------------------------------------------

// Banner message
const BannerMessageList = lazy(() => import('src/pages/dashboard/masterdata/banner-message/list'));
const BannerMessageDetailsPage = lazy(
  () => import('src/pages/dashboard/masterdata/banner-message/details')
);
const BannerMessageCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/banner-message/create')
);
const BannerMessageEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/banner-message/edit')
);

// -----------------------------------------------------------------------

// Consent Management
const CMDocumentList = lazy(
  () => import('src/pages/dashboard/consent-management/cmd-document/list')
);
const CMDocumentDetailsPage = lazy(
  () => import('src/pages/dashboard/consent-management/cmd-document/details')
);
const CMDocumentCreatePage = lazy(
  () => import('src/pages/dashboard/consent-management/cmd-document/create')
);
const CMDocumentEditPage = lazy(
  () => import('src/pages/dashboard/consent-management/cmd-document/edit')
);

// Consents
const ConsentsList = lazy(() => import('src/pages/dashboard/consent-management/consents/list'));
const ConsentsDetailsPage = lazy(
  () => import('src/pages/dashboard/consent-management/consents/details')
);
const ConsentsCreatePage = lazy(
  () => import('src/pages/dashboard/consent-management/consents/create')
);
const ConsentsEditPage = lazy(() => import('src/pages/dashboard/consent-management/consents/edit'));
// Application
const ApplicationList = lazy(
  () => import('src/pages/dashboard/consent-management/application/list')
);
const ApplicationDetailsPage = lazy(
  () => import('src/pages/dashboard/consent-management/application/details')
);
const ApplicationCreatePage = lazy(
  () => import('src/pages/dashboard/consent-management/application/create')
);
const ApplicationEditPage = lazy(
  () => import('src/pages/dashboard/consent-management/application/edit')
);

// ----------------------------------------------------------------------

// Ticket Status
const TicketStatusDetails = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-status/details')
);
const TicketStatusListPage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-status/list')
);
const TicketStatusCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-status/create')
);
const TicketStatusEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-status/edit')
);
// ----------------------------------------------------------------------

// Ticket Category
const TicketCategoryDetails = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-category/details')
);
const TicketCategoryListPage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-category/list')
);
const TicketCategoryCreatePage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-category/create')
);
const TicketCategoryEditPage = lazy(
  () => import('src/pages/dashboard/masterdata/ticket-category/edit')
);
// ----------------------------------------------------------------------

// ticket
const TicketList = lazy(() => import('src/pages/dashboard/ticket-management/tickets/list'));
const TicketDetails = lazy(() => import('src/pages/dashboard/ticket-management/tickets/details'));
const TicketCreatePage = lazy(() => import('src/pages/dashboard/ticket-management/tickets/create'));
const TicketEditPage = lazy(() => import('src/pages/dashboard/ticket-management/tickets/edit'));

// user
const UserList = lazy(() => import('src/pages/dashboard/user-management/user/list'));
const UserDetailsPage = lazy(() => import('src/pages/dashboard/user-management/user/details'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user-management/user/create'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user-management/user/edit'));

// permission
const PermissionList = lazy(() => import('src/pages/dashboard/user-management/permissions/list'));
const PermissionDetailsPage = lazy(
  () => import('src/pages/dashboard/user-management/permissions/details')
);
const PermissionCreatePage = lazy(
  () => import('src/pages/dashboard/user-management/permissions/create')
);
const PermissionEditPage = lazy(
  () => import('src/pages/dashboard/user-management/permissions/edit')
);

// roles
const RolesList = lazy(() => import('src/pages/dashboard/user-management/roles/list'));
const RolesDetailsPage = lazy(() => import('src/pages/dashboard/user-management/roles/details'));
const RolesCreatePage = lazy(() => import('src/pages/dashboard/user-management/roles/create'));
const RolesEditPage = lazy(() => import('src/pages/dashboard/user-management/roles/edit'));

// password policy
const PasswordPolicyPage = lazy(
  () => import('src/pages/dashboard/user-management/password-policy/index')
);

const UsernamePolicyPage = lazy(
  () => import('src/pages/dashboard/user-management/username-policy/index')
);

// Security policy
const SecurityPolicyPage = lazy(
  () => import('src/pages/dashboard/user-management/security-policy/index')
);

// Services
const ServiceList = lazy(() => import('src/pages/dashboard/featuredata/services/list'));

// Features
const FeatureList = lazy(() => import('src/pages/dashboard/featuredata/features/list'));

// Customers
const CustomersList = lazy(() => import('src/pages/dashboard/customers/list'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'banking', element: <BankingPage />, index: true },
      { path: 'customers', element: <CustomersList />, index: true },

      {
        path: 'master-data/countries',
        children: [
          { element: <CountryListPage />, index: true },
          { path: 'list', element: <CountryListPage /> },
          { path: ':id', element: <CountryDetailsPage /> },
          { path: 'new', element: <CountryCreatePage /> },
          { path: ':id/edit', element: <CountryEditPage /> },
        ],
      },

      {
        path: 'master-data/states',
        children: [
          { element: <StateListPage />, index: true },
          { path: 'list', element: <StateListPage /> },
          { path: ':id', element: <StateDetailsPage /> },
          { path: 'new', element: <StateCreatePage /> },
          { path: ':id/edit', element: <StateEditPage /> },
        ],
      },

      {
        path: 'master-data/marital-statuses',
        children: [
          { element: <MaritalStatusesListPage />, index: true },
          { path: 'list', element: <MaritalStatusesListPage /> },
          { path: ':id', element: <MaritalStatusesDetailsPage /> },
          { path: 'new', element: <MaritalStatusesCreatePage /> },
          { path: ':id/edit', element: <MaritalStatusesEditPage /> },
        ],
      },

      {
        path: 'master-data/branch',
        children: [
          { element: <BranchListPage />, index: true },
          { path: 'list', element: <BranchListPage /> },
          { path: ':id', element: <BranchDetailsPage /> },
          { path: 'new', element: <BranchCreatePage /> },
          { path: ':id/edit', element: <BranchEditPage /> },
        ],
      },

      {
        path: 'master-data/occupations',
        children: [
          { element: <OccupationListPage />, index: true },
          { path: 'list', element: <OccupationListPage /> },
          { path: ':id', element: <OccupationDetailsPage /> },
          { path: 'new', element: <OccupationCreatePage /> },
          { path: ':id/edit', element: <OccupationEditPage /> },
        ],
      },

      {
        path: 'master-data/departments',
        children: [
          { element: <DepartmentListPage />, index: true },
          { path: 'list', element: <DepartmentListPage /> },
          { path: ':id', element: <DepartmentDetailsPage /> },
          { path: 'new', element: <DepartmentCreatePage /> },
          { path: ':id/edit', element: <DepartmentEditPage /> },
        ],
      },

      {
        path: 'master-data/id-type',
        children: [
          { element: <IdTypeListPage />, index: true },
          { path: 'list', element: <IdTypeListPage /> },
          { path: ':id', element: <IdTypeDetails /> },
          { path: 'new', element: <IdTypeCreatePage /> },
          { path: ':id/edit', element: <IdTypeEditPage /> },
        ],
      },
      {
        path: 'master-data/account-Purpose',
        children: [
          { element: <AccountPurposesListPage />, index: true },
          { path: 'list', element: <AccountPurposesListPage /> },
          { path: ':id', element: <AccountPurposesDetails /> },
          { path: 'new', element: <AccountPurposesCreatePage /> },
          { path: ':id/edit', element: <AccountPurposesEditPage /> },
        ],
      },
      {
        path: 'master-data/ticket-status',
        children: [
          { element: <TicketStatusListPage />, index: true },
          { path: 'list', element: <TicketStatusListPage /> },
          { path: ':id', element: <TicketStatusDetails /> },
          { path: 'new', element: <TicketStatusCreatePage /> },
          { path: ':id/edit', element: <TicketStatusEditPage /> },
        ],
      },
      {
        path: 'master-data/ticket-category',
        children: [
          { element: <TicketCategoryListPage />, index: true },
          { path: 'list', element: <TicketCategoryListPage /> },
          { path: ':id', element: <TicketCategoryDetails /> },
          { path: 'new', element: <TicketCategoryCreatePage /> },
          { path: ':id/edit', element: <TicketCategoryEditPage /> },
        ],
      },
      {
        path: 'master-data/annual-incomes',
        children: [
          { element: <AnnualIncomeListPage />, index: true },
          { path: 'list', element: <AnnualIncomeListPage /> },
          { path: ':id', element: <AnnualIncomeDetailsPage /> },
          { path: 'new', element: <AnnualIncomeCreatePage /> },
          { path: ':id/edit', element: <AnnualIncomeEditPage /> },
        ],
      },

      {
        path: 'master-data/emp-types',
        children: [
          { element: <EmpTypeListPage />, index: true },
          { path: 'list', element: <EmpTypeListPage /> },
          { path: ':id', element: <EmpTypeDetailsPage /> },
          { path: 'new', element: <EmpTypeCreatePage /> },
          { path: ':id/edit', element: <EmpTypeEditPage /> },
        ],
      },

      {
        path: 'master-data/security-questions',
        children: [
          { element: <SecurityQuestionListPage />, index: true },
          { path: 'list', element: <SecurityQuestionListPage /> },
          { path: ':id', element: <SecurityQuestionDetailsPage /> },
          { path: 'new', element: <SecurityQuestionCreatePage /> },
          { path: ':id/edit', element: <SecurityQuestionEditPage /> },
        ],
      },

      {
        path: 'master-data/banks',
        children: [
          { element: <BankListPage />, index: true },
          { path: 'list', element: <BankListPage /> },
          { path: ':id', element: <BankDetailsPage /> },
          { path: 'new', element: <BankCreatePage /> },
          { path: ':id/edit', element: <BankEditPage /> },
        ],
      },
      {
        path: 'master-data/employment-sector',
        children: [
          { element: <EmploymentSectorListPage />, index: true },
          { path: 'list', element: <EmploymentSectorListPage /> },
          { path: ':id', element: <EmploymentSectorDetailsPage /> },
          { path: 'new', element: <EmploymentSectorCreatePage /> },
          { path: ':id/edit', element: <EmploymentSectorEditPage /> },
        ],
      },
      {
        path: 'master-data/postal-codes',
        children: [
          { element: <PostalCodeListPage />, index: true },
          { path: 'list', element: <PostalCodeListPage /> },
          { path: ':id', element: <PostalCodeDetailsPage /> },
          { path: 'new', element: <PostalCodeCreatePage /> },
          { path: ':id/edit', element: <PostalCodeEditPage /> },
        ],
      },
      {
        path: 'master-data/transaction-request-type',
        children: [
          { element: <TransactionRequestTypeListPage />, index: true },
          { path: 'list', element: <TransactionRequestTypeListPage /> },
          { path: ':id', element: <TransactionRequestTypeDetailsPage /> },
          { path: 'new', element: <TransactionRequestTypeCreatePage /> },
          { path: ':id/edit', element: <TransactionRequestTypeEditPage /> },
        ],
      },
      {
        path: 'master-data/transaction-type',
        children: [
          { element: <TransactionTypeListPage />, index: true },
          { path: 'list', element: <TransactionTypeListPage /> },
          { path: ':id', element: <TransactionTypeDetailsPage /> },
          { path: 'new', element: <TransactionTypeCreatePage /> },
          { path: ':id/edit', element: <TransactionTypeEditPage /> },
        ],
      },
      {
        path: 'master-data/account-closure-reasons',
        children: [
          { element: <AccClosureReasonsListPage />, index: true },
          { path: 'list', element: <AccClosureReasonsListPage /> },
          { path: ':id', element: <AccClosureReasonsDetailsPage /> },
          { path: 'new', element: <AccClosureReasonsCreatePage /> },
          { path: ':id/edit', element: <AccClosureReasonsEditPage /> },
        ],
      },
      {
        path: 'master-data/txn-req-sub-types',
        children: [
          { element: <TransactionRequestSubTypeListPage />, index: true },
          { path: 'list', element: <TransactionRequestSubTypeListPage /> },
          { path: ':id', element: <TransactionRequestSubTypeDetailsPage /> },
          { path: 'new', element: <TransactionRequestSubTypeCreatePage /> },
          { path: ':id/edit', element: <TransactionRequestSubTypeEditPage /> },
        ],
      },
      {
        path: 'master-data/system-config',
        children: [
          { element: <SystemConfigListPage />, index: true },
          { path: 'list', element: <SystemConfigListPage /> },
          { path: ':id', element: <SystemConfigDetailsPage /> },
          { path: 'new', element: <SystemConfigCreatePage /> },
          { path: ':id/edit', element: <SystemConfigEditPage /> },
        ],
      },
      {
        path: 'master-data/banner-message',
        children: [
          { element: <BannerMessageList />, index: true },
          { path: 'list', element: <BannerMessageList /> },
          { path: ':id', element: <BannerMessageDetailsPage /> },
          { path: 'new', element: <BannerMessageCreatePage /> },
          { path: ':id/edit', element: <BannerMessageEditPage /> },
        ],
      },

      // Consent management
      {
        path: 'consent-management/cmd-documents',
        children: [
          { element: <CMDocumentList />, index: true },
          { path: 'list', element: <CMDocumentList /> },
          { path: ':id', element: <CMDocumentDetailsPage /> },
          { path: 'new', element: <CMDocumentCreatePage /> },
          { path: ':id/edit', element: <CMDocumentEditPage /> },
        ],
      },

      {
        path: 'consent-management/consents',
        children: [
          { element: <ConsentsList />, index: true },
          { path: 'list', element: <ConsentsList /> },
          { path: ':id', element: <ConsentsDetailsPage /> },
          { path: 'new', element: <ConsentsCreatePage /> },
          { path: ':id/edit', element: <ConsentsEditPage /> },
        ],
      },
      {
        path: 'consent-management/application-contexts',
        children: [
          { element: <ApplicationList />, index: true },
          { path: 'list', element: <ApplicationList /> },
          { path: ':id', element: <ApplicationDetailsPage /> },
          { path: 'new', element: <ApplicationCreatePage /> },
          { path: ':id/edit', element: <ApplicationEditPage /> },
        ],
      },

      // user management
      {
        path: 'user-management/users',
        children: [
          { element: <UserList />, index: true },
          { path: 'list', element: <UserList /> },
          { path: ':id', element: <UserDetailsPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },

      {
        path: 'user-management/permissions',
        children: [
          { element: <PermissionList />, index: true },
          { path: 'list', element: <PermissionList /> },
          { path: ':id', element: <PermissionDetailsPage /> },
          { path: 'new', element: <PermissionCreatePage /> },
          { path: ':id/edit', element: <PermissionEditPage /> },
        ],
      },
      {
        path: 'user-management/roles',
        children: [
          { element: <RolesList />, index: true },
          { path: 'list', element: <RolesList /> },
          { path: ':id', element: <RolesDetailsPage /> },
          { path: 'new', element: <RolesCreatePage /> },
          { path: ':id/edit', element: <RolesEditPage /> },
        ],
      },
      {
        path: 'user-management/password-policy',
        children: [{ element: <PasswordPolicyPage />, index: true }],
      },

      {
        path: 'user-management/username-policy',
        children: [{ element: <UsernamePolicyPage />, index: true }],
      },
      {
        path: 'user-management/security-policy',
        children: [{ element: <SecurityPolicyPage />, index: true }],
      },

      // ticket management
      {
        path: 'ticket-management/tickets',
        children: [
          { element: <TicketList />, index: true },
          { path: 'list', element: <TicketList /> },
          { path: ':id', element: <TicketDetails /> },
          { path: 'new', element: <TicketCreatePage /> },
          { path: ':id/edit', element: <TicketEditPage /> },
        ],
      },

      // onboarding management
      {
        path: 'onboarding-management/onboarding',
        children: [
          { element: <TicketList />, index: true },
          { path: 'list', element: <TicketList /> },
          { path: ':id', element: <TicketDetails /> },
          { path: 'new', element: <TicketCreatePage /> },
          { path: ':id/edit', element: <TicketEditPage /> },
        ],
      },

      // current user
      {
        path: 'current-user',
        children: [
          { element: <CurrentUser />, index: true },
          { path: 'view', element: <EditCurrentUser /> },
          { path: 'edit', element: <EditCurrentUser /> },
        ],
      },
      {
        path: 'current-user-edit',
        children: [
          { element: <EditCurrentUser />, index: true },
          { path: 'edit', element: <EditCurrentUser /> },
        ],
      },
      {
        path: 'feature-data/services',
        children: [
          { element: <ServiceList />, index: true },
          { path: 'list', element: <ServiceList /> },
        ],
      },
      {
        path: 'feature-data/features',
        children: [
          { element: <FeatureList />, index: true },
          { path: 'list', element: <FeatureList /> },
        ],
      },
    ],
  },
];
