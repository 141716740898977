// react
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import * as React from 'react';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import useNavigatorOnLine from 'src/hooks/use-network-connection';

// slices
import { setNetworkFlag } from 'src/slices/flagsSlice';

// ----------------------------------------------------------------------

export default function NetworkStatusIndicator() {
  const isOnline = useNavigatorOnLine();

  const dispatch = useAppDispatch();

  const { network } = useAppSelector((state) => state.reducer.flags);

  React.useLayoutEffect(() => {
    if (!isOnline) {
      closeSnackbar();
      dispatch(setNetworkFlag(true));
      enqueueSnackbar('You appear to be offline. Check your connection and try again?', {
        variant: 'default',
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: 'bottom', // 'top', 'bottom'
          horizontal: 'center', // 'left', 'center', 'right'
        },
        style: {
          backgroundColor: 'red',
          color: 'white',
          padding: 15,
        },
      });
    }

    if (isOnline && network) {
      closeSnackbar();
      dispatch(setNetworkFlag(false));
      enqueueSnackbar('You are back online', {
        variant: 'default',
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: 'top', // 'top', 'bottom'
          horizontal: 'center', // 'left', 'center', 'right'
        },
        style: {
          backgroundColor: '#43a047',
          color: 'white',
          padding: 15,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return null;
}
