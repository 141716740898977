import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/login'));

// forgot password
const ForgotPassword = lazy(() => import('src/pages/auth/forgot-password'));

// reset password
const ResetPassword = lazy(() => import('src/pages/auth/reset-password'));

// update password
const ChangePassword = lazy(() => import('src/pages/auth/change-password'));

// ----------------------------------------------------------------------

const authJwt = {
  path: '',
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: <JwtLoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'change-password',
      element: <ChangePassword />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
