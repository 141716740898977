import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type userResponse = {
  id: string;
  username: string;
  full_name: string;
  email: string;
  mobile_number: string;
  user_permissions: [];
};

const initialState: userResponse = {
  id: '',
  username: '',
  full_name: '',
  email: '',
  mobile_number: '',
  user_permissions: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userResponse>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
