// react
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useActiveLink(path: string, deep = true): boolean {
  const { pathname } = useLocation();

  const currentPath = path === '/' ? '/' : `${path}`;
  const asPath = pathname;
  const splitted = currentPath.slice(0, currentPath.lastIndexOf('/'));
  const currentSplitted = currentPath.split('/');
  const pathSplitted = asPath.split('/');

  const isCurrent =
    currentSplitted[0] === pathSplitted[0] &&
    currentSplitted[1] === pathSplitted[1] &&
    currentSplitted[2] === pathSplitted[2] &&
    currentSplitted[3] === pathSplitted[3];

  const normalActive = pathname === currentPath || isCurrent;
  const deepActive = pathname.includes(currentPath) || asPath.includes(splitted);

  return deep ? deepActive : normalActive;
}
