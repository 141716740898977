import { BannerRoot } from 'src/components/reldyn/dialog-modal';
import { baseQuery } from 'src/store/basequery/basequery';
import {
  ForgotPassRequest,
  LoginRequest,
  LoginResponse,
  ResetPassRequest,
  ResetPassResponse,
} from 'src/types/auth';

const loginApi = baseQuery.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/security/v1/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

const forgotPassApi = baseQuery.injectEndpoints({
  endpoints: (build) => ({
    forgotPassword: build.mutation<null, ForgotPassRequest>({
      query: (email) => ({
        url: '/security/v1/auth/forgot-password',
        method: 'POST',
        body: email,
      }),
    }),
  }),
});

const resetPassApi = baseQuery.injectEndpoints({
  endpoints: (build) => ({
    resetPass: build.mutation<ResetPassResponse, ResetPassRequest>({
      query: (credentials) => ({
        url: '/security/v1/auth/change-password',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

const getBannerApi = baseQuery.injectEndpoints({
  endpoints: (build) => ({
    banner: build.query<BannerRoot, null>({
      query: () => ({
        url: '/master-data/v1/banners/enabled/before',
        method: 'GET',
      }),
    }),
  }),
});

export const { useLoginMutation } = loginApi;
export const { useForgotPasswordMutation } = forgotPassApi;
export const { useResetPassMutation } = resetPassApi;
export const { useBannerQuery } = getBannerApi;
