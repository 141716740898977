// react
import React, { useEffect } from 'react';

// @mui
import { Alert, AlertTitle, Box, Collapse, Stack, Typography } from '@mui/material';

// components
import Markdown from 'src/components/markdown';
import BannerDialog, { BannerProps } from 'src/components/reldyn/dialog-modal';

// layouts
import LoginLayout from 'src/layouts/login';

// hooks
import useIsFeatureOn from 'src/hooks/useIsFeatureOn';

// types

// utils
import { isPresent } from 'src/utils/functions';

// sections
import AuthLoginForm from 'src/sections/auth/AuthLoginForm';

// ------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------
export default function Login({ bannerData }: BannerProps) {
  const [open, setOpen] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);

  // feature flags
  const { isEnabled: stickyFeature } = useIsFeatureOn('LOGIN_PAGE_STICKY_BANNER', true);
  const { isEnabled: popupFeature } = useIsFeatureOn('LOGIN_PAGE_POPUP_BANNER', true);

  // ------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (bannerData?.sticky !== null) {
      setOpen(true);
    }
    if (bannerData?.popup !== null) {
      setOpenPopUp(true);
    }
  }, [bannerData]);

  // ------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (bannerData?.popup?.timeout !== undefined && bannerData?.popup?.timeout > 0) {
      const timer = bannerData?.popup?.timeout;
      setTimeout(() => {
        setOpenPopUp(false);
      }, timer * 1000);
    }
  }, [bannerData?.popup?.timeout]);

  // ------------------------------------------------------------------------------------------------
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        {/* sticky banner */}
        {stickyFeature && isPresent(bannerData?.sticky?.title) && bannerData?.sticky && (
          <Box sx={{ width: '100%' }}>
            <Collapse in={open}>
              <Alert
                severity={bannerData?.sticky?.severity ?? 'info'}
                sx={{ mb: 3 }}
                variant="standard"
                onClose={bannerData?.sticky?.closable ? () => setOpen(false) : undefined}
              >
                <AlertTitle>{bannerData?.sticky?.title}</AlertTitle>
                <Markdown
                  sx={{
                    paddingTop: 1,
                    paddingRight: 2,
                    paddingBottom: 1,
                    '& p, li, ol': {
                      typography: 'body2',
                    },
                    '& ol': {
                      p: 0,
                      display: { md: 'flex' },
                      listStyleType: 'none',
                      '& li': {
                        '&:first-of-type': {
                          minWidth: 240,
                          mb: { md: 0 },
                        },
                      },
                    },
                  }}
                >
                  {bannerData?.sticky?.message ?? ''}
                </Markdown>
              </Alert>
            </Collapse>
          </Box>
        )}

        {/* pop banner */}
        {popupFeature && isPresent(bannerData?.popup?.title) && (
          <BannerDialog
            bannerData={bannerData?.popup ?? null}
            open={openPopUp}
            close={() => setOpenPopUp(!openPopUp)}
          />
        )}
        <Typography variant="h3">Sign in to Digital Platform</Typography>
        <AuthLoginForm />
      </Stack>
    </LoginLayout>
  );
}
