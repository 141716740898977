import { PasswordUpdate, ResetSubmit } from 'src/types/auth';
import { UserFormResponse } from 'src/types/user/user';
import { API_ROOT } from 'src/utils/constants';
import { ApiClient } from '../config';

const AUTH_URL = `${API_ROOT}/security/v1`;

export const refreshToken = () => {
  const resp = ApiClient.post(`${AUTH_URL}/auth/refresh-token`);
  return resp;
};

export const doLogout = () => {
  const resp = ApiClient.get(`${AUTH_URL}/auth/logout`);
  return resp;
};

export const getMe = () => {
  const resp = ApiClient.get(`${AUTH_URL}/auth/me`);
  return resp;
};

export const getAvatar = () => {
  const resp = ApiClient.get(`${AUTH_URL}/user-avatars/user-data`);
  return resp;
};

export const getUserDetails = () => {
  const resp = ApiClient.get(`${AUTH_URL}/users/current-user`);
  return resp;
};

export const getUserActivityLog = () => {
  const resp = ApiClient.get(`${AUTH_URL}/user-activity-logs`);
  return resp;
};

export const updateCurrentUser = (data: UserFormResponse) => {
  const resp = ApiClient.put(`${AUTH_URL}/users/update-current-user`, data);
  return resp;
};

export const uploadAvatar = (imgData: Blob) => {
  const data = new FormData();
  data.append('avatar', imgData);
  const resp = ApiClient.post(`${AUTH_URL}/user-avatars`, data);
  return resp;
};

export const resetPasswordApi = (data: ResetSubmit) => {
  const resp = ApiClient.post(`${AUTH_URL}/auth/reset-password`, data);
  return resp;
};

export const tokenValidateApi = (data: { token: string }) => {
  const resp = ApiClient.post(`${AUTH_URL}/auth/validate-token`, data);
  return resp;
};

export const changePasswordApi = (data: PasswordUpdate) => {
  const resp = ApiClient.post(`${AUTH_URL}/auth/change-password`, data);
  return resp;
};
