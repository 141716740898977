// react
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// pages
import Login from 'src/pages/auth/login';

// hooks
import { useAppSelector } from 'src/hooks/hooks';

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children: React.ReactNode;
};

// ----------------------------------------------------------------------
export default function AuthGuard({ children }: Readonly<AuthGuardProps>) {
  const pathname = window.location.href.toString();
  const navigate = useNavigate();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  // redux
  const { isAuthenticated } = useAppSelector((state) => state.reducer.login);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (requestedLocation && pathname !== requestedLocation) {
      navigate(requestedLocation);
    }
    if (isAuthenticated) {
      setRequestedLocation(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pathname, navigate, requestedLocation]);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  return <> {children} </>;
}
