import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LoginResponse } from 'src/types/auth';

const initialState: LoginResponse = {
  token: '',
  refresh_token: '',
  expires_at: 0,
  refresh_expires_at: 0,
  isAuthenticated: false,
  change_password_at_next_login: false,
  password_expiry_after_frequency_days: false
};

export const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<LoginResponse>) => {
      state = { ...state, ...action.payload };
      state.isAuthenticated = true;
      return state;
    },
    logout: (state) => {
      const myState = state;
      if (myState) state = initialState;
      return state;
    },
    pwdChange: (state, action: PayloadAction<boolean>) => {
      state.change_password_at_next_login = action.payload;
    },
    pwdExpiryChange:  (state, action: PayloadAction<boolean>) => {
      state.password_expiry_after_frequency_days = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setToken, logout, pwdChange, pwdExpiryChange, setAuthenticated } = authSlice.actions;

export default authSlice.reducer;
