// react
import { m } from 'framer-motion';
import { useEffect, useState } from 'react';

// @mui
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';

// routes
import { useRouter } from 'src/routes/hook';

// components
import { varHover } from 'src/components/animate';
import ConfirmDialog from 'src/components/confirm-dialog';
import { CustomAvatar } from 'src/components/custom-avatar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

// paths
import { paths } from 'src/routes/paths';

// services
import { doLogout, getAvatar } from 'src/services/auth/auth';

// slices
import { logout } from 'src/slices/authSlice';
import { setCurrentUser } from 'src/slices/currentUserSlice';
import { setPopUp } from 'src/slices/flagsSlice';

// utils
import Iconify from 'src/components/iconify';
import { snackbarErrorResponse } from 'src/utils/functions';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
    icon: 'eva:home-outline',
  },
  {
    label: 'Profile',
    linkTo: paths.dashboard.currentUser.user,
    icon: 'eva:person-outline',
  },
  {
    label: 'Settings',
    linkTo: '/#2',
    icon: 'eva:settings-2-outline',
  },
];

// ----------------------------------------------------------------------
export default function AccountPopover() {
  const { replace, push } = useRouter();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state.reducer.user);
  const { changeProfile } = useAppSelector((state) => state.reducer.currentUser);
  const popover = usePopover();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [, setOpenPopover] = useState<HTMLElement | null>(null);
  const [imageData, setImageData] = useState('');

  // ----------------------------------------------------------------------
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // ----------------------------------------------------------------------
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  // ----------------------------------------------------------------------
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  // ----------------------------------------------------------------------
  const handleLogout = async () => {
    try {
      const resp = await doLogout();
      const { status } = resp;
      if (status === 200) {
        dispatch(logout());
        dispatch(setPopUp(false));
        replace(paths.auth.login);
        handleClosePopover();
      }
    } catch (error) {
      console.error(error);
      snackbarErrorResponse(error, 'Unable to logout!');
    }
  };

  // ----------------------------------------------------------------------
  const handleClickItem = (path: string) => {
    popover.onClose();
    push(path);
  };

  // ----------------------------------------------------------------------
  const getCurrentAvatar = async () => {
    try {
      const res = await getAvatar();
      if (res.status === 200) {
        setImageData(res.data);
        dispatch(setCurrentUser(false));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCurrentAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------
  useEffect(() => {
    if (changeProfile) getCurrentAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeProfile]);

  // ----------------------------------------------------------------------
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <CustomAvatar
          src={`data:image/png;base64, ${imageData}`}
          alt={`${userDetails?.full_name} avatar`}
          name={`${userDetails?.full_name} avatar`}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userDetails?.full_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userDetails?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              <Iconify icon={option.icon} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          <Iconify icon="eva:log-out-fill" />
          Logout
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Logout"
        content="Are you sure you want to logout?"
        action={
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="eva:log-out-fill" />}
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </Button>
        }
      />
    </>
  );
}
