// react
import { useMemo } from 'react';

// routes
import { paths } from 'src/routes/paths';

// components
import Label from 'src/components/label';
import SvgColor from 'src/components/svg-color';

// hooks
import useIsFeatureOn from 'src/hooks/useIsFeatureOn';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const component_icon = (name: string) => (
  <SvgColor src={`/assets/icons/components/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  dataGrid: component_icon('ic_data_grid'),
};

// ----------------------------------------------------------------------

type MasterDataItem = {
  title: string;
  path: string;
};

export function useNavData() {
  // feature flags
  const { isEnabled: featureCountry } = useIsFeatureOn('MD_COUNTRIES_LISTING', true);
  const { isEnabled: featureState } = useIsFeatureOn('FF_MD_STATES_LIST', true);
  const { isEnabled: featureBank } = useIsFeatureOn('FF_MD_BANKS_LIST', true);
  const { isEnabled: featureOccupation } = useIsFeatureOn('FF_MD_OCCUPATIONS_LIST', true);
  const { isEnabled: featureDepartment } = useIsFeatureOn('FF_MD_DEPARTMENTS_LIST', true);

  const { isEnabled: featureAnnualIncome } = useIsFeatureOn('FF_MD_ANNUAL_INCOME_LIST', true);
  const { isEnabled: featureSecurityQues } = useIsFeatureOn('FF_MD_SECURITY_QUES_LIST', true);
  const { isEnabled: featureEmpType } = useIsFeatureOn('FF_MD_EMP_TYPES_LIST', true);
  const { isEnabled: featurePostalCode } = useIsFeatureOn('FF_MD_POSTAL_CODE_LIST', true);
  const { isEnabled: featureEmpSector } = useIsFeatureOn('FF_MD_EMP_SECTORS_LIST', true);
  const { isEnabled: featureTxnReqType } = useIsFeatureOn('FF_MD_TXN_REQ_TYPES_LIST', true);

  const { isEnabled: featureTxnType } = useIsFeatureOn('FF_MD_TXN_TYPES_LIST', true);
  const { isEnabled: featureAcctClosureReason } = useIsFeatureOn(
    'FF_MD_ACCOUNT_CLOSURE_REASON_LIST',
    true
  );
  const { isEnabled: featureSystemConfig } = useIsFeatureOn('FF_MD_SYSTEM_CONFIGS_LIST', true);
  const { isEnabled: featureBranches } = useIsFeatureOn('FF_MD_BRANCHES_LIST', true);
  const { isEnabled: featureIdType } = useIsFeatureOn('FF_MD_ID_TYPE_LIST', true);
  const { isEnabled: featureAcctPurposes } = useIsFeatureOn('FF_MD_ACCOUNTS_PURPOSES_LIST', true);
  const { isEnabled: featureTxnReqSubType } = useIsFeatureOn('FF_MD_TXN_REQ_SUB_TYPE_LIST', true);
  const { isEnabled: featureMaritalStatuses } = useIsFeatureOn('FF_MD_MARITAL_STATUSES_LIST', true);
  const { isEnabled: featureTicketStatuses } = useIsFeatureOn('FF_MD_TICKET_STATUSES_LIST', true);
  const { isEnabled: featureTicketCategories } = useIsFeatureOn(
    'FF_MD_TICKET_CATEGORIES_LIST',
    true
  );
  const { isEnabled: featureBannerMessage } = useIsFeatureOn('FF_MD_BANNER_MESSAGES_LIST', true);

  const masterDataChildren: MasterDataItem[] = [];

  if (featureBank) {
    masterDataChildren.splice(0, 0, { title: 'Banks', path: paths.dashboard.banks.root });
  }
  if (featureCountry) {
    masterDataChildren.splice(1, 0, { title: 'Countries', path: paths.dashboard.countries.root });
  }
  if (featureState) {
    masterDataChildren.splice(2, 0, { title: 'States', path: paths.dashboard.states.root });
  }
  if (featureOccupation) {
    masterDataChildren.splice(3, 0, {
      title: 'Occupations',
      path: paths.dashboard.occupations.root,
    });
  }
  if (featureDepartment) {
    masterDataChildren.splice(4, 0, {
      title: 'Departments',
      path: paths.dashboard.departments.root,
    });
  }
  if (featureAnnualIncome) {
    masterDataChildren.splice(5, 0, {
      title: 'Annual Income',
      path: paths.dashboard.annualIncomes.root,
    });
  }
  if (featureSecurityQues) {
    masterDataChildren.splice(6, 0, {
      title: 'Security Questions',
      path: paths.dashboard.securityQuestions.root,
    });
  }
  if (featureEmpType) {
    masterDataChildren.splice(7, 0, {
      title: 'Employment Types',
      path: paths.dashboard.empTypes.root,
    });
  }
  if (featurePostalCode) {
    masterDataChildren.splice(8, 0, {
      title: 'Postal Codes',
      path: paths.dashboard.postalCode.root,
    });
  }
  if (featureEmpSector) {
    masterDataChildren.splice(9, 0, {
      title: 'Employment Sectors',
      path: paths.dashboard.employmentSector.root,
    });
  }
  if (featureTxnReqType) {
    masterDataChildren.splice(10, 0, {
      title: 'Txn Request Types',
      path: paths.dashboard.transactionRequestType.root,
    });
  }

  if (featureTxnType) {
    masterDataChildren.splice(11, 0, {
      title: 'Txn Types',
      path: paths.dashboard.transactionType.root,
    });
  }
  if (featureAcctClosureReason) {
    masterDataChildren.splice(12, 0, {
      title: 'Account Closure Reasons',
      path: paths.dashboard.accountClosureReasons.root,
    });
  }
  if (featureSystemConfig) {
    masterDataChildren.splice(13, 0, {
      title: 'System Configs',
      path: paths.dashboard.sysConfigs.root,
    });
  }
  if (featureBranches) {
    masterDataChildren.splice(14, 0, {
      title: 'Branches',
      path: paths.dashboard.branch.root,
    });
  }
  if (featureIdType) {
    masterDataChildren.splice(15, 0, {
      title: 'ID Types',
      path: paths.dashboard.idtype.root,
    });
  }
  if (featureAcctPurposes) {
    masterDataChildren.splice(16, 0, {
      title: 'Account Purposes',
      path: paths.dashboard.accountPurpose.root,
    });
  }
  if (featureTxnReqSubType) {
    masterDataChildren.splice(17, 0, {
      title: 'Txn Request Sub Types',
      path: paths.dashboard.txnReqSubTypes.root,
    });
  }
  if (featureMaritalStatuses) {
    masterDataChildren.splice(18, 0, {
      title: 'Marital Statuses',
      path: paths.dashboard.maritalStatuses.root,
    });
  }
  if (featureTicketStatuses) {
    masterDataChildren.splice(19, 0, {
      title: 'Ticket Statuses',
      path: paths.dashboard.ticketStatus.root,
    });
  }
  if (featureTicketCategories) {
    masterDataChildren.splice(20, 0, {
      title: 'Ticket Categories',
      path: paths.dashboard.ticketCategory.root,
    });
  }
  if (featureBannerMessage) {
    masterDataChildren.splice(21, 0, {
      title: 'Banner Messages',
      path: paths.dashboard.bannerMessage.root,
    });
  }

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'digital platform',
        items: [
          { title: 'Mobile App', path: paths.dashboard.root, icon: ICONS.analytics },
          { title: 'Banking', path: paths.dashboard.banking, icon: ICONS.banking },
          { title: 'Customers', path: paths.dashboard.customers, icon: ICONS.user },
        ],
      },

      // Operations
      {
        subheader: 'Operations',
        items: [
          {
            title: 'Onboarding',
            path: paths.dashboard.onboardingManagement.root,
            icon: ICONS.invoice,
          },
          {
            title: 'Tickets',
            path: paths.dashboard.ticketManagement.root,
            icon: ICONS.file,
            info: (
              <>
                <Label variant="soft" color="error">
                  +32{' '}
                </Label>
                &nbsp;
                <Label variant="soft" color="secondary">
                  128{' '}
                </Label>
              </>
            ),
          },
        ],
      },

      // Regulatory compliance
      {
        subheader: 'Regulatory compliance',
        items: [
          {
            title: 'Consent management',
            path: paths.dashboard.consentManagement.root,
            icon: ICONS.lock,
            children: [
              { title: 'Documents', path: paths.dashboard.consentManagement.root },
              { title: 'Consents', path: paths.dashboard.consents.root },
              { title: 'Application Contexts', path: paths.dashboard.application.root },
            ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          {
            title: 'user management',
            path: paths.dashboard.userManagement.root,
            icon: ICONS.user,
            children: [
              { title: 'users', path: paths.dashboard.userManagement.root },
              { title: 'Roles', path: paths.dashboard.roles.root },
              { title: 'Permissions', path: paths.dashboard.permissions.root },
              { title: 'Password Policy', path: paths.dashboard.passwordPolicy.root },
              { title: 'Username Policy', path: paths.dashboard.usernamePolicy.root },
              { title: 'Security Policy', path: paths.dashboard.securityPolicy.root },
            ],
          },
        ],
      },
      // CONFIGURATION
      {
        subheader: 'Configuration',
        items: [
          {
            title: 'Feature Flags',
            path: paths.dashboard.services.list,
            icon: ICONS.menuItem,
          },
          {
            title: 'Master Data',
            path: paths.dashboard.countries.root,
            icon: ICONS.dataGrid,
            children: masterDataChildren,
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      featureCountry,
      featureState,
      featureBank,
      featureOccupation,
      featureDepartment,
      featureAnnualIncome,
      featureSecurityQues,
      featureEmpType,
      featurePostalCode,
      featureEmpSector,
      featureTxnReqType,
      featureTxnType,
      featureAcctClosureReason,
      featureSystemConfig,
      featureBranches,
      featureIdType,
      featureAcctPurposes,
      featureTxnReqSubType,
      featureMaritalStatuses,
      featureTicketStatuses,
      featureTicketCategories,
      featureBannerMessage,
    ]
  );

  return data;
}
