import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type InitialState = {
  popup: boolean;
  network: boolean;
};

const initialState: InitialState = {
  popup: true,
  network: false,
};

const flagsSlice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setPopUp: (state, action: PayloadAction<boolean>) => {
      state.popup = action.payload;
    },
    setNetworkFlag: (state, action: PayloadAction<boolean>) => {
      state.network = action.payload;
    },
  },
});

export const { setPopUp, setNetworkFlag } = flagsSlice.actions;

export default flagsSlice.reducer;
