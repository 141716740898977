// react
import { useCallback, useEffect } from 'react';

// @mui
import Box from '@mui/material/Box';

// hooks
import { useAppDispatch } from 'src/hooks/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

// components
import { useSettingsContext } from 'src/components/settings';

// slices
import { setFeatures } from 'src/slices/featureSlices';
import { setUser } from 'src/slices/userSlice';

// services
import { getFeatureFlagsList } from 'src/services/feature-flags/features';
import { getMe } from '../../services/auth/auth';

// layouts
import Header from './header';
import Main from './main';
import NavHorizontal from './nav-horizontal';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const dispatch = useAppDispatch();
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const getData = useCallback(async () => {
    try {
      const res = await getMe();
      const { data, status } = res;

      if (status === 200) {
        dispatch(setUser(data));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const getFeatureData = useCallback(async () => {
    try {
      const resp = await getFeatureFlagsList();

      const { data: featuresData, status } = resp;
      if (status === 200) {
        dispatch(setFeatures({ features: featuresData, disable: false }));
      }
    } catch (err) {
      dispatch(setFeatures({ features: [], disable: true }));
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getFeatureData();
  }, [getFeatureData]);

  // const TIMEOUT_DURATION = 600000;

  // let logoutTimer: NodeJS.Timeout;

  // function resetLogoutTimer() {
  //   clearTimeout(logoutTimer);
  //   logoutTimer = setTimeout(handleSessionLogout, TIMEOUT_DURATION);
  // }

  // function handleUserActivity() {
  //   resetLogoutTimer();
  // }

  // function handleSessionLogout() {
  //   dispatch(logout());
  //   dispatch(setPopUp(false));
  //   dispatch(setConnection(true));
  //   replace(paths.auth.login);
  //   window.removeEventListener('mousemove', handleUserActivity);
  //   window.removeEventListener('keydown', handleUserActivity);
  // }

  // function initializeApp() {
  //   document.addEventListener('mousemove', handleUserActivity);
  //   document.addEventListener('keypress', handleUserActivity);
  //   resetLogoutTimer();
  // }
  // initializeApp();

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main sx={{ overflow: 'hidden' }}>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main sx={{ overflow: 'hidden' }}>{children}</Main>
      </Box>
    </>
  );
}
