// @mui
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Container, Divider } from '@mui/material';

// hooks
// import { useMockedUser } from 'src/hooks/use-mocked-user';

// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------
export default function NavUpgrade() {
  // const { user } = useMockedUser();

  return (
    <>
      <Divider />
      <Container
        sx={{
          px: 2,
          pt: 2,
          pb: 4,
          textAlign: 'center',
          bottom: 0,
          left: 0,
        }}
      >
        <Stack alignItems="center">
          {/* <Box>
            <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }} />
          </Box> */}

          <Stack spacing={0.5} sx={{ mt: 0, mb: 2 }}>
            {/* <Typography variant="subtitle2" noWrap>
              {user?.displayName}
            </Typography> */}
            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              please reach us at <br />
              <a
                href="mailto:baas-support@reldyn.co"
                style={{ textDecoration: 'none', color: '#ee0979' }}
              >
                baas-support@reldyn.co
              </a>
            </Typography>
          </Stack>
        </Stack>
        <Button variant="outlined" href={paths.url} target="_blank" rel="noopener">
          Documentation
        </Button>
      </Container>
    </>
  );
}
