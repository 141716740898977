import { format, formatDistanceToNow, getTime } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat ?? 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat ?? 'dd MMM yyyy p';

  if (date === '' || date === undefined || date === null) {
    return '--';
  }
  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function convertToISO8601(dateString: InputValue): string {
  const date = new Date(dateString!);

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

  return isoString;
}

export function convertToCustomFormat(isoString: string | Date): string | Date {
  const date: string | Date = new Date(isoString);
  return date;
}

export function convertDateToISO8601(dateString: InputValue): string {
  const date = new Date(dateString!);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;

  return isoString;
}
