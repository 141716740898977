import axios, { AxiosError } from 'axios';

// types
import { ConsentType } from 'src/types/consent-management/application';

// snackbar
import { enqueueSnackbar } from 'src/components/snackbar';
import { SNACKBAR_OPTIONS_FOR_ERROR } from './constants';

export const getFieldValue = (param: unknown): string => {
  if (
    param === undefined ||
    param === null ||
    param === '' ||
    param === 'null' ||
    typeof param === 'object'
  ) {
    return '--';
  }

  // Check if the parameter is a string
  if (typeof param === 'string') {
    return param;
  }

  // Handle other types by converting them to a string
  return String(param);
};

export const getFieldValueNumber = (param: unknown): string | number => {
  if (param === undefined || param === null || param === 'null' || param === '') {
    return '--';
  }
  if (typeof param === 'number') {
    return param;
  }

  // Handle other types by converting them to a string
  return String(param);
};

export const getFieldValeEmpty = (param: string | undefined | null | number): string | number => {
  if (param === undefined || param === null || param === '') {
    return '';
  }
  if (typeof param === 'number') {
    return param;
  }
  return param;
};

export const getDateFieldValue = (param: string | undefined | null | Date): string | Date => {
  if (param === undefined || param === null || param === '') {
    return '';
  }
  if (typeof param === 'string') {
    return param;
  }
  if (param instanceof Date) {
    return param;
  }
  return param;
  // we can handle with exception if input does not match
  // throw new Error('Unsupported input type');
};

export const isPresent = (str: undefined | null | string): boolean => {
  if (str === undefined) return false;
  if (str === null) return false;
  if (str === '') return false;
  return true;
};

export class QuerySearchParam {
  constructor(op: string, values: string[]) {
    this.op = op;

    this.values = values;
  }

  op: string;

  values: string[];
}

export const convertMapToJson = (params: Map<string, QuerySearchParam>) =>
  JSON.stringify(Object.fromEntries(params));

export const slugify = (params: string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return params
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export function generateBreadcrumbs(
  rootPath: string,
  middlePath: string,
  middleName: string,
  lastName: string
) {
  const breadcrumbs = [
    { name: 'Dashboard', href: rootPath },
    {
      name: middleName,
      href: middlePath,
    },
    { name: lastName },
  ];

  return breadcrumbs;
}

export const getMappingItemValues = (item: ConsentType | string) => {
  if (typeof item === 'string') {
    return item;
  }
  return item.id;
};

export function snackbarErrorResponse(
  error: AxiosError<AxiosError>,
  param = 'Uh oh! Something went wrong.',
  key?: string
) {
  if (axios.isAxiosError(error)) {
    if (error?.response?.data?.message && error?.response?.data?.message !== '') {
      enqueueSnackbar(error?.response?.data?.message, {
        key,
        ...SNACKBAR_OPTIONS_FOR_ERROR,
      });
    } else if (error?.message && error?.message !== '') {
      enqueueSnackbar(error?.message, {
        key,
        ...SNACKBAR_OPTIONS_FOR_ERROR,
      });
    } else if (error?.response?.statusText && error?.response?.statusText !== '') {
      enqueueSnackbar(error?.response?.statusText, {
        key,
        ...SNACKBAR_OPTIONS_FOR_ERROR,
      });
    } else {
      enqueueSnackbar("Uh oh! Something went wrong.", {
        key,
        ...SNACKBAR_OPTIONS_FOR_ERROR,
      });
    }
  } else {
    enqueueSnackbar(param, {
      key,
      ...SNACKBAR_OPTIONS_FOR_ERROR,
    });
  }
}

export function snackbarCustomError(param: string) {
  enqueueSnackbar(param, {
    ...SNACKBAR_OPTIONS_FOR_ERROR,
  });
}

export function snackbarSuccessResponse(success: string, key?: string) {
  enqueueSnackbar(success ?? 'Successful', { key });
}
